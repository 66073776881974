import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Container,
    Box,
    Divider,
    useMediaQuery,
    useTheme
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQSection = () => {
    const faqData = [
        {
            question: "How long does song registration take?",
            answer: "Most songs are registered within 2-3 business days after submission."
        },
        {
            question: "Which territories do you cover?",
            answer: "Our global network of partners ensures worldwide collection coverage across major markets and territories."
        },
        {
            question: "How do I track my earnings?",
            answer: "Access real-time earnings data through your dashboard, with detailed breakdowns by territory, source, and time period."
        },
        {
            question: "What information do I need to register songs?",
            answer: "Basic song information including title, writers, publishers, split percentages, and associated recordings."
        },
        {
            question: "How does billing work?",
            answer: "Simple, transparent pricing with no hidden fees. Subscribe monthly or annually based on your catalog size."
        },
        {
            question: "How do I change my account email?",
            answer: "Easily update your email and account settings through your secure account dashboard."
        }
    ];

    // Custom SVG as React JSX
    const CustomExpandIcon = () => (
        <svg
            width="24"
            height="26"
            viewBox="0 0 24 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 14H16M22 14C22 19.5228 17.5228 24 12 24C6.47715 24 2 19.5228 2 14C2 8.47715 6.47715 4 12 4C17.5228 4 22 8.47715 22 14Z"
                stroke="#099250"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect if the screen is mobile size

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    p: 1,
                    m: 1,
                }}
            >
                <Typography variant="h4" sx={{ fontWeight: 600, marginTop: "60px", textAlign: 'center' }}>
                    <span className='text-dark-theam'>{isMobile ? 'Common' : 'Frequently asked'}</span> <span className='text-theam'>questions</span>
                </Typography>
            </Box>
            <Box sx={{ py: 4 }}>
                <Container maxWidth="lg">
                    {faqData.map((faq, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                            <Accordion elevation={0} sx={{ boxShadow: 'none' }}>
                                <AccordionSummary
                                    expandIcon={<CustomExpandIcon />}
                                    aria-controls={`panel${index}-content`}
                                    id={`panel${index}-header`}
                                >
                                    <Typography variant="h6" sx={{ color: "#03301B" }}>{faq.question}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{ color: "#475467", marginRight: "4rem" }} >{faq.answer}</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Divider />
                        </Box>
                    ))}
                </Container>
            </Box>
        </>
    );
};

export default FAQSection;
