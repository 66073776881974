import React, { useState } from 'react';
import { Box, Button, Fade, Modal, TextField, Typography, IconButton, Grid, AccordionDetails, AccordionSummary, Accordion, Stack, Alert, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";


export default function ReqestEarlyForm() {

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        company: '',
        phone: ''
    });

    const [toast, setToast] = useState({
        open: false,
        message: '',
        severity: 'success', // 'error', 'warning', 'info'
    });

    const handleToastClose = () => {
        setToast((prev) => ({ ...prev, open: false }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateEmail = (input) => {
        const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        return emailPattern.test(input);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let save = true;
        if (!formData.firstname) {
            save = false;
            // toast.error("please enter your first name !");
            setToast({
                open: true,
                message: 'Please enter your first name!',
                severity: 'error',
            });
        } else if (!formData.lastname) {
            save = false;
            // toast.error("please enter your last name !");
            setToast({
                open: true,
                message: 'Please enter your last name!',
                severity: 'error',
            });
        } else if (!formData.email) {
            save = false;
            // toast.error("please enter your email !");
            setToast({
                open: true,
                message: 'Please enter your email!',
                severity: 'error',
            });
        } else if (!validateEmail(formData.email)) {
            save = false;
            // toast.error("please provide valid email");
            setToast({
                open: true,
                message: 'Please provide a valid email!',
                severity: 'error',
            });
        } else if (!formData.company) {
            save = false;
            // toast.error("please enter your password !");
            setToast({
                open: true,
                message: 'Please enter your company name!',
                severity: 'error',
            });
        }

        if (save) {
            const url = `${process.env.REACT_APP_API_BASE_URL}/user/zoho-api`;

            const response = await axios.post(url, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });
            let accessToken = response.data.access_token;

            const urls = `${process.env.REACT_APP_API_BASE_URL}/user/create-zoho-lead`;

            // const leadresponse = await axios.post(urls, formData, config);
            const leadresponse = await axios.post(urls,
                formData,
                { headers: { "Authorization": "Bearer " + accessToken } },
            )
                .then((response) => {
                    if (response) {
                        setToast({
                            open: true,
                            message: 'Request sent successfully!',
                            severity: 'success',
                        });
                        setFormData({ firstname: "", lastname: "", email: "", company: "", phone: "" });
                        // toast.success("Request sent successfuly.");
                    }
                }).catch((error) => {
                    setToast({
                        open: true,
                        message: 'Something went wrong!',
                        severity: 'error',
                    });
                    // toast.error("Something went wrong!");
                });
            // toast.success("Request sent successfuly.");
        }
    }
    return (
        <div>
            <Box className="modal-body" sx={{ padding: 2 }}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            {/* <Accordion defaultExpanded> */}
                            <Grid container spacing={2}>
                                {/* First Name */}
                                <Grid item xs={12} md={6}>
                                    <AccordionDetails sx={{ padding: '8px 16px' }}>
                                        <Box className="field-wrapper">
                                            <Box className="relative end-icon-field">
                                                <TextField
                                                    className="input-ui"
                                                    placeholder="First Name"
                                                    fullWidth
                                                    name="firstname"
                                                    value={formData.firstname}
                                                    onChange={handleChange}
                                                />
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </Grid>

                                {/* Last Name */}
                                <Grid item xs={12} md={6}>
                                    <AccordionDetails sx={{ padding: '8px 16px' }}>
                                        <Box className="field-wrapper">
                                            <Box className="relative end-icon-field">
                                                <TextField
                                                    className="input-ui"
                                                    placeholder="Last Name"
                                                    fullWidth
                                                    name="lastname"
                                                    value={formData.lastname}
                                                    onChange={handleChange}
                                                />
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </Grid>

                                {/* Company */}
                                <Grid item xs={12} md={6}>
                                    <AccordionDetails sx={{ padding: '8px 16px' }}>
                                        <Box className="field-wrapper">
                                            <Box className="relative end-icon-field">
                                                <TextField
                                                    className="input-ui"
                                                    placeholder="Company"
                                                    fullWidth
                                                    name="company"
                                                    value={formData.company}
                                                    onChange={handleChange}
                                                />
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </Grid>

                                {/* Phone Number */}
                                <Grid item xs={12} md={6}>
                                    <AccordionDetails sx={{ padding: '8px 16px' }}>
                                        <Box className="field-wrapper">
                                            <Box className="relative end-icon-field">
                                                <TextField
                                                    className="input-ui"
                                                    placeholder="Phone Number"
                                                    fullWidth
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                />
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </Grid>

                                {/* Email (Full-width) */}
                                <Grid item xs={12}>
                                    <AccordionDetails sx={{ padding: '8px 16px' }}>
                                        <Box className="field-wrapper">
                                            <Box className="relative end-icon-field">
                                                <TextField
                                                    className="input-ui"
                                                    placeholder="Email"
                                                    fullWidth
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </Grid>

                                {/* Submit Button */}
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                                        <Button type="submit" variant="contained" className="bg-btn-color">
                                            Submit
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            {/* </Accordion> */}
                        </Grid>
                    </Grid>
                </form>
            </Box>
            
                {/* Snackbar for Toast */}
                <Snackbar
                open={toast.open}
                autoHideDuration={3000}
                onClose={handleToastClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleToastClose} severity={toast.severity} sx={{ width: '100%' }}>
                    {toast.message}
                </Alert>
            </Snackbar>
        </div>
    )
}
