import React from "react";
import { Box, Card, CardContent, Typography, Grid, Collapse } from "@mui/material";
import Homebgimg from "../assets/img/homebg.png";
import Homeimg from "../assets/img/homeimg.png";
import ResponsiveContainer from "./ResponsiveContainer";
import ReqestEarlyForm from "./ReqestEarlyForm";

export default function HomeSection({ open, handleOpen }) {
  return (
    <ResponsiveContainer>
      <Box sx={{ padding: { md: "50px 0", xs: "0" } }}>
        <Card
          sx={{
            height: '100%',
            margin: { md: "10px 0px", xs: 0 },
            borderRadius: { md: 4, xs: 0 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "50px",
            backgroundImage: `url(${Homebgimg})`,
            color: "#fff",
          }}
          className="remove-card-shadow cover-img"
        >
          <CardContent sx={{ textAlign: "center", padding: 3, marginTop: 5 }}>
            <Typography className="home-section-second-text" variant="body2" sx={{ mb: 2 }}>
              YOUR SONGS. YOUR ROYALTIES. YOUR CONTROL.
            </Typography>
            <Typography sx={{ mb: 1, fontWeight: 600 }} className="home-section-first-text">
              The DIY publishing<br /> administration platform
            </Typography>
            <Typography className="home-section-third-text" sx={{ mb: 4 }}>
              Register your songs🎶, <span className="mobile-br"> <br /> </span>
              Collect Global Royalties🌎💰
            </Typography>

            {!open &&
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  margin: `${!open ? "25px 0 85px 0" : "10px 0"}`,
                  maxWidth: "100%",
                }}
              >
                <input
                  style={{
                    color: "#fff",
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                    border: "1px solid #ebedf1",
                    padding: 10,
                    flex: 1,
                    fontFamily: "Figtree, sans-serif",
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                  }}
                  onClick={() => handleOpen(true)}
                  placeholder="Enter your email"
                />
                <button
                  style={{
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                    border: "none",
                    fontWeight: "bold",
                    padding: 10,
                    flexShrink: 0,
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "Figtree, sans-serif",
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                  }}
                  onClick={() => handleOpen(true)}
                >
                  Request early access
                </button>
              </Box>
            }
            {/* {open &&
              <Box sx={{ margin: "25px 0 85px 0" }}>
                <ReqestEarlyForm />
              </Box>
            } */}
            <Collapse
                in={open}
                timeout={1500} // Animation duration set to 1.5 seconds
                unmountOnExit
            >
                <Box sx={{ margin: "15px 0 85px 0" }}>
                    <ReqestEarlyForm />
                </Box>
            </Collapse>
          </CardContent>
        </Card>
        <Grid container spacing={4} justifyContent="center" sx={{ marginTop: -20, position: 'relative' }}>
          <Grid item xs={10} sm={8} md={8} sx={{ zIndex: 1, position: 'relative' }}>
            {/* Greenish shadow behind the image */}
            <Box
              sx={{
                position: "absolute",
                top: 20,
                left: 0,
                right: 20,
                bottom: 20,
                zIndex: 0,
                boxShadow: "-50px 20px 60px rgba(10, 143, 81, 0.1), 0 10px 30px rgba(10, 143, 81, 0.05)",
                borderRadius: 5,
                backgroundColor: "rgba(10, 143, 81, 0.1)",
                filter: "blur(20px)",
              }}
            />

            <img src={Homeimg} alt="Home" width="100%" height="100%" style={{ position: "relative", zIndex: 1 }} />
          </Grid>
        </Grid>
      </Box>
    </ResponsiveContainer>
  );
}
