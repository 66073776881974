import { Box, Container, Typography } from '@mui/material'
import React from 'react';
import Footerlogo from "../../assets/img/footer-logo.png";

export default function Footer() {
    return (
        <Box className='bg-color' sx={{
            backgroundColor: 'green',
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
            color: '#fff',
            padding: {md: '55px 0', xs: '5px 0 35px 0'}
        }}>
            <Container maxWidth="lg">
                <Box
                    sx={{
                        display: {
                            xs: 'block',
                            md: 'flex',
                        },
                        justifyContent: {
                            md: 'space-between',
                        },
                        alignItems: {
                            md: 'center',
                        },
                        padding: {
                            md: 0,
                            xs: '35px'
                        }
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <img src={Footerlogo} alt="img" className='footer-logo' />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10 }}>
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 10.3399C20 4.78332 15.5229 0.278809 10 0.278809C4.47715 0.278809 0 4.78332 0 10.3399C0 15.3616 3.65684 19.524 8.4375 20.2788V13.2482H5.89844V10.3399H8.4375V8.12333C8.4375 5.60177 9.9305 4.20893 12.2146 4.20893C13.3088 4.20893 14.4531 4.40544 14.4531 4.40544V6.88142H13.1922C11.95 6.88142 11.5625 7.65703 11.5625 8.45271V10.3399H14.3359L13.8926 13.2482H11.5625V20.2788C16.3432 19.524 20 15.3618 20 10.3399Z" fill="white" />
                        </svg>
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13 0.278809H5C2.23858 0.278809 0 2.51739 0 5.27881V13.2788C0 16.0402 2.23858 18.2788 5 18.2788H13C15.7614 18.2788 18 16.0402 18 13.2788V5.27881C18 2.51739 15.7614 0.278809 13 0.278809ZM16.25 13.2788C16.2445 15.0714 14.7926 16.5233 13 16.5288H5C3.20735 16.5233 1.75549 15.0714 1.75 13.2788V5.27881C1.75549 3.48616 3.20735 2.0343 5 2.02881H13C14.7926 2.0343 16.2445 3.48616 16.25 5.27881V13.2788ZM13.75 5.52881C14.3023 5.52881 14.75 5.08109 14.75 4.52881C14.75 3.97653 14.3023 3.52881 13.75 3.52881C13.1977 3.52881 12.75 3.97653 12.75 4.52881C12.75 5.08109 13.1977 5.52881 13.75 5.52881ZM9 4.77881C6.51472 4.77881 4.5 6.79353 4.5 9.27881C4.5 11.7641 6.51472 13.7788 9 13.7788C11.4853 13.7788 13.5 11.7641 13.5 9.27881C13.5027 8.08451 13.0294 6.93838 12.1849 6.09389C11.3404 5.2494 10.1943 4.77615 9 4.77881ZM6.25 9.27881C6.25 10.7976 7.4812 12.0288 9 12.0288C10.5188 12.0288 11.75 10.7976 11.75 9.27881C11.75 7.76001 10.5188 6.52881 9 6.52881C7.4812 6.52881 6.25 7.76001 6.25 9.27881Z" fill="white" />
                        </svg>
                        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.1761 0.278809H16.9362L10.9061 7.05621L18 16.2788H12.4456L8.0951 10.6854L3.11723 16.2788H0.35544L6.80517 9.02961L0 0.278809H5.69545L9.6279 5.39143L14.1761 0.278809ZM13.2073 14.6542H14.7368L4.86441 1.81809H3.2232L13.2073 14.6542Z" fill="white" />
                        </svg>
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 0.278809C0.67157 0.278809 0 0.950379 0 1.77881V16.7788C0 17.6072 0.67157 18.2788 1.5 18.2788H16.5C17.3284 18.2788 18 17.6072 18 16.7788V1.77881C18 0.950379 17.3284 0.278809 16.5 0.278809H1.5ZM5.52076 4.28153C5.52639 5.23778 4.81061 5.827 3.96123 5.82278C3.16107 5.81856 2.46357 5.18153 2.46779 4.28294C2.47201 3.43778 3.13998 2.75856 4.00764 2.77825C4.88795 2.79794 5.52639 3.44341 5.52076 4.28153ZM9.2797 7.04057H6.75971H6.7583V15.6004H9.4217V15.4007C9.4217 15.0208 9.4214 14.6408 9.4211 14.2607C9.4203 13.2469 9.4194 12.232 9.4246 11.2185C9.426 10.9724 9.4372 10.7165 9.5005 10.4816C9.7381 9.60411 10.5271 9.03741 11.4074 9.17671C11.9727 9.26521 12.3467 9.59291 12.5042 10.1259C12.6013 10.4591 12.6449 10.8177 12.6491 11.1651C12.6605 12.2127 12.6589 13.2603 12.6573 14.308C12.6567 14.6778 12.6561 15.0478 12.6561 15.4176V15.599H15.328V15.3937C15.328 14.9417 15.3278 14.4898 15.3275 14.0379C15.327 12.9084 15.3264 11.7789 15.3294 10.649C15.3308 10.1385 15.276 9.63511 15.1508 9.14151C14.9638 8.40741 14.5771 7.79991 13.9485 7.36121C13.5027 7.049 13.0133 6.84791 12.4663 6.82541C12.404 6.82282 12.3412 6.81943 12.2781 6.81602C11.9984 6.8009 11.7141 6.78554 11.4467 6.83947C10.6817 6.99275 10.0096 7.34291 9.5019 7.96021C9.4429 8.03101 9.3852 8.10291 9.2991 8.21021L9.2797 8.23451V7.04057ZM2.68164 15.6032H5.33242V7.04614H2.68164V15.6032Z" fill="white" />
                        </svg>
                        <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.593 2.23914C20.4791 1.81654 20.2565 1.43114 19.9473 1.12131C19.6382 0.811471 19.2533 0.588001 18.831 0.47314C17.265 0.0431402 11 0.0361404 11 0.0361404C11 0.0361404 4.73596 0.0291402 3.16896 0.44014C2.74689 0.560287 2.36279 0.786917 2.05353 1.09827C1.74427 1.40963 1.52025 1.79526 1.40296 2.21814C0.989964 3.78414 0.985963 7.03214 0.985963 7.03214C0.985963 7.03214 0.981963 10.2961 1.39196 11.8461C1.62196 12.7031 2.29696 13.3801 3.15496 13.6111C4.73696 14.0411 10.985 14.0481 10.985 14.0481C10.985 14.0481 17.25 14.0551 18.816 13.6451C19.2385 13.5305 19.6237 13.3075 19.9336 12.9983C20.2435 12.6891 20.4673 12.3044 20.583 11.8821C20.997 10.3171 21 7.07014 21 7.07014C21 7.07014 21.02 3.80514 20.593 2.23914ZM8.99596 10.0411L9.00096 4.04114L14.208 7.04614L8.99596 10.0411Z" fill="white" />
                        </svg>
                    </div>
                </Box>
                <hr style={{ border: 'none', borderTop: '1px solid #ccc', margin: '16px 0' }} />
                
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column-reverse', md: 'row' },
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        textAlign: { xs: 'center', md: 'left' },
                        gap: 2,
                        mt: 4,
                    }}
                >
                    {/* Typography for Copyright */}
                    <Typography
                        variant="body2"
                        sx={{
                            mb: { xs: 2, md: 0 },
                            color: '#fff',
                        }}
                    >
                        © Copyright Singles Publishing 2024. All Right Reserved.
                    </Typography>

                    {/* Links */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: { xs: 'center', md: 'flex-end' },
                            gap: 2,
                            mb: { xs: 5, md: 0 },
                        }}
                    >
                        <a href="#" style={{ color: '#fff', textDecoration: 'underline' }}>Privacy Policy</a>
                        <a href="#" style={{ color: '#fff', textDecoration: 'underline' }}>Terms of Service</a>
                        <a href="#" style={{ color: '#fff', textDecoration: 'underline' }}>Cookies Settings</a>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}
