import React from 'react';
import { Box, Typography, Container, useMediaQuery } from '@mui/material';
import Avatars from "../assets/img/avatars.png";
import { useTheme } from '@emotion/react';

export default function Streamline() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{ padding: 2 }}>
            <Container maxWidth="lg">
                <Box sx={{
                    textAlign: 'center',
                    p: "8px 0",
                    m: "8px 0",
                }}>
                    <Typography variant="h4" sx={{ fontWeight: 600, marginTop: "40px", }}>
                        <span className='text-dark-theam'>Ready to</span>{' '}
                        <span className='text-theam'>streamline</span><br />
                        <span className='text-dark-theam'>your music publishing?</span>
                    </Typography>

                    <div style={{ display: 'inline-block', margin: '30px 0', width: "100%" }} className=''>
                        <input className='fix-input' style={{ color: 'black', border: '1px solid #ebedf1', padding: 10, fontFamily: "'Figtree', sans-serif", boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)" }} placeholder="Enter your email" />
                        <button className='bg-btn-color fix-btn' style={{ color: '#fff', border: 'none', padding: 10, fontFamily: "'Figtree', sans-serif", cursor: "pointer", boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)" }}>Join waitlist</button>
                    </div>

                    <Typography variant="body2" sx={{ fontWeight: 600, marginTop: "20px", fontSize: "1.2rem" }}>
                        <span className='step-song-theme'>Trusted by 200+ independent songwriters and publishers</span>{' '}
                        <span className={`${!isMobile ? "step-theme" : "step-song-theme"}`}>worldwide</span>
                    </Typography>
                </Box>
                <div style={{ textAlign: 'center', marginBottom: "3rem" }}>
                    <img src={Avatars} alt="img" width={320} height="100%" />
                </div>
            </Container>
        </Box>
    )
}
