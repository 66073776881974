import React from 'react';
import { Box, Typography } from '@mui/material';
import Partner1 from "../assets/img/partner1.png";
import Partner2 from "../assets/img/partner2.png";
import Partner3 from "../assets/img/partner3.png";
import Partner4 from "../assets/img/partner4.png";
import Partner5 from "../assets/img/partner5.png";
import Partner6 from "../assets/img/partner6.png";
import Partner7 from "../assets/img/partner7.png";

import Partner8 from "../assets/img/partner8.png";
import Partner9 from "../assets/img/partner9.png";
import Partner10 from "../assets/img/partner10.png";
import Partner11 from "../assets/img/partner11.png";
import Partner12 from "../assets/img/partner12.png";
import Partner13 from "../assets/img/partner13.png";
import Partner14 from "../assets/img/partner14.png";

const imagesRow1 = [Partner1, Partner2, Partner3, Partner4, Partner5, Partner6, Partner7];
const imagesRow2 = [Partner8, Partner9, Partner10, Partner11, Partner12, Partner13, Partner14];

const Carousel = () => {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    p: 1,
                    m: 1,
                }}
            >
                <Typography variant="h4" sx={{ fontWeight: 600, marginTop: "30px", textAlign: 'center' }}>
                    <span className='text-dark-theam'>Global collection</span> <span className='text-theam'>partners</span>
                </Typography>
            </Box>
            <Box
                sx={{
                    overflow: 'hidden',
                    width: '100%',
                    //   backgroundColor: '#f9f9f9',
                    pb: 4,
                    pt: 1,
                    position: 'relative',
                }}
            >
                {/* Row 1 */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        animation: 'scroll 20s linear infinite',
                        '@keyframes scroll': {
                            from: { transform: 'translateX(0)' },
                            to: { transform: 'translateX(-100%)' },
                        },
                    }}
                >
                    {[...imagesRow1, ...imagesRow2, ...imagesRow1, ...imagesRow2].map((image, index) => (
                        <Box
                            key={`row1-${index}`}
                            component="img"
                            src={image}
                            alt={`Logo ${index}`}
                            sx={{
                                height: 50,
                                flexShrink: 0,
                            }}
                        />
                    ))}
                </Box>

                {/* Row 2 */}
                {/* <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        animation: 'scroll 20s linear infinite',
                        '@keyframes scroll': {
                            from: { transform: 'translateX(0)' },
                            to: { transform: 'translateX(-100%)' },
                        },
                        mt: 3,
                    }}
                >
                    {[...imagesRow2, ...imagesRow2, ...imagesRow2, ...imagesRow2].map((image, index) => (
                        <Box
                            key={`row2-${index}`}
                            component="img"
                            src={image}
                            alt={`Logo ${index}`}
                            sx={{
                                height: 50,
                                flexShrink: 0,
                            }}
                        />
                    ))}
                </Box> */}
            </Box>
        </>
    );
};

export default Carousel;
