import * as React from 'react';
import Box from '@mui/material/Box';
import Faq from '../components/Faq';
import Partners from '../components/Partners';
import Container from '@mui/material/Container';
import Royalties from '../components/Royalties';
import Catalog from '../components/Catalog';
import HomeSection from '../components/HomeSection';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Streamline from '../components/Streamline';
import ReqestEarlyModalPopup from '../components/ReqestEarlyModalPopup';

export default function BoxBasic() {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // const [reqestEarlyForm, setReqestEarlyForm] = React.useState(false);

    return (
        <>
            <div className="">
                <Header handleOpen={handleOpen}/>

                <HomeSection open={open} handleOpen={handleOpen} />
                <Catalog />
                <Royalties />
                <Partners />
                <Faq />
                <Streamline />

                <Footer />

                {/* <ReqestEarlyModalPopup open={open} handleClose={handleClose} /> */}
            </div>
        </>
    );
}
