import * as React from 'react';
import { Box, Container, AppBar, Toolbar, IconButton, Typography, Menu, MenuItem } from '@mui/material';
import Logo from "../../assets/img/logo.png";
import Responsivelogo from "../../assets/img/footer-logo.png";
import Homebgimg from "../../assets/img/homebg.png";
import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';
import TogalHeader from './TogalHeader';

// const pages = ['Products', 'Pricing', 'Blog'];
const pages = ['Login'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar({ handleOpen }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: { md: 'unset', xs: 'red' },
        backgroundImage: {
          xs: `url(${Homebgimg || ''})`,
          md: 'none',
        },
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      className="remove-card-shadow"
    >
      <Container maxWidth="lg">
        {/* <Container maxWidth="md"> */}
        <Toolbar disableGutters sx={{ backgroundColor: 'unset', color: '#fff', padding: '10px 0' }}>
          {/* Desktop header */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={Logo} alt="img" width={250} height="auto" className='' />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            <div>
              <button className='btn' style={{
                // backgroundColor: '#F0F0F0',
                // borderTop: '1px solid #ccc',
                // borderLeft: '1px solid #ccc',
                // borderRight: '1px solid #ccc',
                // borderBottom: '2px solid #ccc',
                // padding: 10,
                // marginRight: 10,
                // borderRadius: 8,
                // boxShadow: '0 2px 3px #CED3DB',
                background: "linear-gradient(to bottom, #FFFFFF 0%, #F3F3F3 100%)",
                color: "#333",
                fontWeight: "bold",
                borderTop: "1.2px solid #ccc",
                borderLeft: "1.5px solid #ccc",
                borderRight: "1.5px solid #ccc",
                borderBottom: "2.2px solid #ccc",
                borderRadius: "8px",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                padding: "10px 20px",
                cursor: "pointer",
                fontFamily: "'Figtree', sans-serif",
                marginRight: 10,
              }} onClick={() => handleOpen(true)}>Request early access</button>
              <button className='bg-btn-color'
                style={{
                  color: '#fff',
                  border: 'none',
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 16,
                  paddingRight: 16,
                  borderRadius: 11,
                  fontFamily: "'Figtree', sans-serif",
                  borderTop: "1.2px solid #04673A",
                  borderLeft: "1.5px solid #04673A",
                  borderRight: "1.5px solid #04673A",
                  borderBottom: "2.2px solid #04673A",
                }}>Login</button>
            </div>
          </Box>


          {/* Responsive header */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <TogalHeader handleOpen={handleOpen} />
          </Box>
          {/* <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={Responsivelogo} alt="img" width={250} height="auto" className='' />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography sx={{ textAlign: 'center' }}>{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar >
  );
}

export default ResponsiveAppBar;


