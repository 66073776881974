import React from 'react';
import { Box, Card, CardContent, Typography, Grid, Avatar, Container } from '@mui/material';
import { green } from '@mui/material/colors';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import DashboardIcon from '@mui/icons-material/Dashboard';

import Royaltiesimg from "../assets/img/royalties.png";
import Royalties1 from "../assets/img/royalties1.png";
import Royalties2 from "../assets/img/royalties2.png";
import Royalties3 from "../assets/img/royalties3.png";

const features = [
    {
        title: "Real-time earnings tracking",
        description: "Get instant visibility into your royalty earnings across all revenue sources. Track performance, mechanical, and digital royalties in one unified dashboard.",
        icon: <DashboardIcon sx={{ fontSize: 40, color: 'white' }} />,
        color: green[600],
    },
    {
        title: "Revenue insights at a glance",
        description: "See exactly where your money comes from with detailed breakdowns by revenue type, territory, and time period. Make informed decisions with comprehensive analytics.",
        icon: <AccessTimeIcon sx={{ fontSize: 40, color: 'white' }} />,
        color: green[600],
    },
    {
        title: "Growth visualization",
        description: "Follow your earnings trends with historical data visualization. Compare performance across different periods and identify growth opportunities.",
        icon: <ShowChartIcon sx={{ fontSize: 40, color: 'white' }} />,
        color: green[600],
    },
];

const Royalties = () => {
    return (
        <Box sx={{ padding: 2, margin: '20px 0', marginTop: "65px" }}>
            <Container maxWidth="lg">

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    // p: 1,
                    // m: 1,
                }}>
                    <Typography sx={{ fontWeight: 600, textAlign: 'center', }}>
                        <span className='text-dark-theam'>Track and collect your </span>{' '}
                        <span className='text-theam'>royalties</span>
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: "1rem"
                }}>
                    <Typography
                        sx={{ color: '#475467', textAlign: 'center', mb: 2, fontWeight: 500, fontSize: "1.17rem" }}
                    >
                        Never miss a payment with comprehensive royalty tracking across all revenue streams and territories.
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    p: 1,
                    m: 1,
                    // bgcolor: 'background.paper',
                    // borderRadius: 1,
                }}>
                    <img src={Royaltiesimg} alt="img" width="100%" height="100%" />
                </Box>
                <div className='show-on-large hide-on-small'>
                    <Grid container spacing={4} justifyContent="center" sx={{ paddingTop: "20px" }} >

                        <Grid item xs={12} sm={6} md={4}>
                          

                            <Card
                                sx={{
                                    borderRadius: 3,
                                    outline: 'none',
                                    backgroundColor: '#FCFCFC',
                                    minHeight: 200,
                                    padding: 2
                                }}
                                className='remove-card-shadow'
                            >
                                <CardContent sx={{ textAlign: '', padding: 1 }}>
                                    <Box
                                        sx={{
                                            backgroundColor: 'unset',
                                            display: 'flex',
                                            justifyContent: '',
                                            alignItems: '',
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                marginRight: 2,
                                                backgroundColor: 'unset',
                                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                                borderRadius: 2,
                                            }}
                                        >
                                            <img src={Royalties1} alt="img" width={20} height={20} />
                                        </Avatar>
                                        <div>
                                            <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                                            <span className='step-theme'>Real-time</span>{' '}
                                            <span className='step-song-theme'>earnings tracking</span>
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{ color: '#475467' }}
                                                className='small-text-size'
                                            >
                                                 Get instant visibility into your royalty earnings across all revenue sources. Track performance, mechanical, and digital royalties in one unified dashboard.
                                            </Typography>
                                        </div>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Card
                                sx={{
                                    borderRadius: 3,
                                    outline: 'none',
                                    backgroundColor: '#FCFCFC',
                                    minHeight: 200,
                                    padding: 2
                                }}
                                className='remove-card-shadow'
                            >
                                <CardContent sx={{ textAlign: '', padding: 1 }}>
                                    <Box
                                        sx={{
                                            backgroundColor: 'unset',
                                            display: 'flex',
                                            justifyContent: '',
                                            alignItems: '',
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                marginRight: 2,
                                                backgroundColor: 'unset',
                                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                                borderRadius: 2,
                                            }}
                                        >
                                            <img src={Royalties2} alt="img" width={20} height={20} />
                                        </Avatar>
                                        <div>
                                            <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                                                <span className='step-song-theme'>Revenue insights</span>{' '}
                                                <span className='step-theme'>at a glance</span>
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{ color: '#475467' }}
                                                className='small-text-size'
                                            >
                                                See exactly where your money comes from with detailed breakdowns by revenue type, territory, and time period. Make informed decisions with comprehensive analytics.
                                            </Typography>
                                        </div>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Card
                                sx={{
                                    borderRadius: 3,
                                    outline: 'none',
                                    backgroundColor: '#FCFCFC',
                                    minHeight: 200,
                                    padding: 2
                                }}
                                className='remove-card-shadow'
                            >
                                <CardContent sx={{ textAlign: '', padding: 1 }}>
                                    <Box
                                        sx={{
                                            backgroundColor: 'unset',
                                            display: 'flex',
                                            justifyContent: '',
                                            alignItems: '',
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                marginRight: 2,
                                                backgroundColor: 'unset',
                                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                                borderRadius: 2,
                                            }}
                                        >
                                            <img src={Royalties3} alt="img" width={20} height={20} />
                                        </Avatar>
                                        <div>
                                            <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                                                <span className="step-song-theme">Growth</span><br />
                                                <span className="step-theme">visualization</span>
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{ color: '#475467' }}
                                                className='small-text-size'
                                            >
                                                Follow your earnings trends with historical data visualization. Compare
                                                performance across different periods and identify growth opportunities.
                                            </Typography>
                                        </div>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                </div>

                <div className='show-on-small hide-on-large'>
                    <Grid container spacing={4} justifyContent="center">

                        <Grid item xs={12} sm={6} md={4}>
                            <Card
                                sx={{
                                    borderRadius: 3,
                                    outline: 'none',
                                    // minHeight: 312
                                }}
                                className='remove-card-shadow'
                            >
                                <CardContent sx={{ textAlign: '', padding: 1 }}>
                                    <Box
                                        sx={{
                                            backgroundColor: 'unset',
                                            display: 'flex',
                                            justifyContent: '',
                                            alignItems: '',
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                marginRight: 2,
                                                backgroundColor: 'unset',
                                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                                borderRadius: 2,
                                            }}
                                        >
                                            <img src={Royalties1} alt="img" width={20} height={20} />
                                        </Avatar>
                                        <div>
                                            <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                                                <span className='track-royality-theme'>Monitor your earnings in real-time</span>
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{ color: '#475467' }}
                                                className='small-text-size'
                                            >
                                                Never miss a payment with comprehensive royalty tracking across all revenue streams and territories.
                                            </Typography>
                                        </div>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Card
                                sx={{
                                    borderRadius: 3,
                                    outline: 'none',
                                    // minHeight: 312
                                }}
                                className='remove-card-shadow'
                            >
                                <CardContent sx={{ textAlign: '', padding: 1 }}>
                                    <Box
                                        sx={{
                                            backgroundColor: 'unset',
                                            display: 'flex',
                                            justifyContent: '',
                                            alignItems: '',
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                marginRight: 2,
                                                backgroundColor: 'unset',
                                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                                borderRadius: 2,
                                            }}
                                        >
                                            <img src={Royalties2} alt="img" width={20} height={20} />
                                        </Avatar>
                                        <div>
                                            <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                                                <span className='track-royality-theme '>Clear revenue insights</span>
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{ color: '#475467' }}
                                                className='small-text-size'
                                            >
                                                See exactly where your money comes from with detailed breakdowns by revenue type, territory, and time period. Make informed decisions with comprehensive analytics.
                                            </Typography>
                                        </div>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Card
                                sx={{
                                    borderRadius: 3,
                                    outline: 'none',
                                    // minHeight: 312,
                                }}
                                className='remove-card-shadow'
                            >
                                <CardContent sx={{ textAlign: '', padding: 1 }}>
                                    <Box
                                        sx={{
                                            backgroundColor: 'unset',
                                            display: 'flex',
                                            justifyContent: '',
                                            alignItems: '',
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                marginRight: 2,
                                                backgroundColor: 'unset',
                                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                                borderRadius: 2,
                                            }}
                                        >
                                            <img src={Royalties3} alt="img" width={20} height={20} />
                                        </Avatar>
                                        <div>
                                            <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                                                <span className='track-royality-theme '>Track your growth</span>
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{ color: '#475467' }}
                                                className='small-text-size'
                                            >
                                                Follow your earnings trends with historical data visualization. Compare
                                                performance across different periods and identify growth opportunities.
                                            </Typography>
                                        </div>
                                    </Box>

                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                </div>
            </Container>
        </Box>
    );
};

export default Royalties;
