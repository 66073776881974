import React from "react";
import { Box, Card, CardContent, Typography, Grid, Container, useMediaQuery } from '@mui/material';
import Catalog1 from "../assets/img/catalog1.png";
import Catalog2 from "../assets/img/catalog2.png";
import Catalog3 from "../assets/img/catalog3.png";
import { useTheme } from "@emotion/react";

export default function Catalog() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{ padding: 1 }}>
            <Container maxWidth="lg">
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                }}>
                    <Typography sx={{ fontWeight: 600, textAlign: 'center', marginTop: "5rem" }}>
                        <span className='text-dark-theam'>Manage your catalog </span>{' '}
                        <span className='text-theam'>effortlessly</span>
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginTop: "1rem"
                }}>
                    <Typography
                        sx={{ color: '#475467', textAlign: 'center', mb: 6, fontWeight: 500, fontSize: "1.10rem" }}
                    >
                        A powerful yet simple interface to handle all your songs, registrations,<br /> and metadata. Keep your entire catalog organized and up-to-date.
                    </Typography>
                </Box>
                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} sm={12} md={12}>
                        <img src={Catalog1} alt="img" width="100%" height="100%" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Card
                            sx={{
                                margin: '10px 0px',
                                backgroundColor: '#fcfcfc',
                                border: '1px solid #ebedf1',
                                borderRadius: 5,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 0
                            }}
                            className='remove-card-shadow'
                        >
                            <CardContent sx={{ textAlign: '', padding: 3, paddingBottom: '0 !important' }}>
                                <Typography variant="body2" sx={{ fontWeight: 600, mb: 1 }}>
                                    <span className='step-theme'>STEP 1</span>
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: 600, mb: 1 }}>
                                    <span className='step-song-theme'>Add a song to your Catalog</span>
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary', mb: 4 }} className='small-text-size'>
                                    Search for and attach any associated recordings of your song from the Spotify API embedded into the platform
                                </Typography>
                                <img src={Catalog2} alt="img" width="100%" height="100%" />
                            </CardContent>

                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Card
                            sx={{
                                margin: '10px 0px',
                                backgroundColor: '#fcfcfc',
                                border: '1px solid #ebedf1',
                                borderRadius: 5,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 0
                            }}
                            className='remove-card-shadow'
                        >
                            <CardContent sx={{ textAlign: '', padding: 3, paddingTop: '0 !important' }}>
                                {/* For mobile, Step 2 comes before the image */}
                                {isMobile && (
                                    <>
                                        <Typography variant="body2" sx={{ fontWeight: 600, mb: 1, mt: 4 }}>
                                            <span className='step-theme'>STEP 2</span>
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontWeight: 600, mb: 1 }}>
                                            <span className='step-song-theme'>IP Chain / Inputting splits</span>
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#475467' , marginBottom: "2rem" }} className='small-text-size'>
                                            After attaching the associated recordings, users input full publishing splits (adding up to 100%), and simply click submit to register their songs.
                                        </Typography>
                                    </>
                                )}
                                <img src={Catalog3} alt="img" width="100%" height="100%" />
                                {/* For desktop, Step 2 comes after the image */}
                                {!isMobile && (
                                    <>
                                        <Typography variant="body2" sx={{ fontWeight: 600, mb: 1, mt: 4 }}>
                                            <span className='step-theme'>STEP 2</span>
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontWeight: 600, mb: 1 }}>
                                            <span className='step-song-theme'>IP Chain / Inputting splits</span>
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#475467' }} className='small-text-size' >
                                            After attaching the associated recordings, users input full publishing splits (adding up to 100%), and simply click submit to register their songs.
                                        </Typography>
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}