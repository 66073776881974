import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Typography,
    Box,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Homebgimg from "../../assets/img/homebg.png";
import Logo from "../../assets/img/logo.png";
import Responsivelogo from "../../assets/img/footer-logo.png";
import CloseIcon from '@mui/icons-material/Close';

const MobileNavigation = ({ handleOpen }) => {
    const [open, setOpen] = useState(false);

    const toggleDrawer = (state) => () => {
        console.log('state:', state)
        setOpen(state);
    };

    return (
        <>
            {/* AppBar for Mobile Navigation */}
            <AppBar position="fixed"
                sx={{
                    display: { xs: 'block', md: 'none' },
                    backgroundColor: { md: 'unset', xs: 'unset' },
                    backgroundImage: {
                        xs: `url(${Homebgimg || ''})`,
                        md: 'none',
                    },
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}
                className="remove-card-shadow"
            >
                <Toolbar sx={{}}>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img src={Responsivelogo} alt="img" width={250} height="auto" className='' />
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            {/* Overlay Drawer with Custom Transition */}
            <Drawer
                anchor="top"
                open={open}
                onClose={toggleDrawer(false)}
                transitionDuration={400}
                PaperProps={{
                    sx: {
                        height: '30%',
                        // backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        // backgroundColor: '',
                        color: '#000',
                        p: 2,
                        border: 'unset',
                        borderBottomLeftRadius: 25,
                        borderBottomRightRadius: 25
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    role="presentation"
                >
                    {/* Logo Section */}
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            // display: 'flex',
                        }}
                    >
                        <img src={Logo} alt="img" width={250} height="auto" />
                    </Typography>

                    {/* Close Button Section */}
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={toggleDrawer(false)}
                        sx={{
                            padding: '0 12px 12px 0'
                        }}
                    >
                        <CloseIcon sx={{ fontSize: 35 }} />
                    </IconButton>
                </Box>
                <div style={{ display: 'inline-block', margin: '25px 0' }} className='fix-input-btn'>
                    <button className='fix-btn'
                        style={{
                            marginBottom: 20,
                            background: "linear-gradient(to bottom, #FFFFFF 0%, #F3F3F3 100%)",
                            color: "#333",
                            fontWeight: "bold",
                            borderTop: "1.2px solid #ccc",
                            borderLeft: "1.5px solid #ccc",
                            borderRight: "1.5px solid #ccc",
                            borderBottom: "2.2px solid #ccc",
                            borderRadius: "12px",
                            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                            padding: "10px 20px",
                            cursor: "pointer",
                            fontFamily: "'Figtree', sans-serif",
                            paddingTop: 15,
                            paddingBottom: 15,
                            padding: 20,
                            fontSize: 20,
                            fontWeight: 600

                        }} onClick={() => { setOpen(false); handleOpen(true) }}>Request early access</button>
                    <button className='bg-btn-color fix-btn'
                        style={{
                            color: '#fff',
                            padding: 16, fontSize: 20,
                            fontWeight: 600,
                            borderRadius: 12,
                            fontFamily: "'Figtree', sans-serif",
                            borderTop: "1.2px solid #04673A",
                            borderLeft: "1.5px solid #04673A",
                            borderRight: "1.5px solid #04673A",
                            borderBottom: "2.2px solid #04673A",
                        }}>Sign in</button>
                </div>
            </Drawer>
        </>
    );
};

export default MobileNavigation;
