import React from 'react';
import { Container } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';

const ResponsiveContainer = ({ children }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg')); // Desktop: md and above

  return isDesktop ? <Container maxWidth="lg">{children}</Container> : <>{children}</>;
};

export default ResponsiveContainer;
